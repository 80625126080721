<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('statForm')" @cancel="cancel" :stateFlag="true"></add-header>
    <el-form ref="statForm" :model="statForm" :rules="rules" label-width="120px" size="mini">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="出运发票号" prop="tran_no">
            <el-input
              v-model="statForm.tran_no"
              maxlength="30"
              @input="val => (statForm.tran_no = helper.transverseTranNo(val).trim())"
              show-word-limit
              placeholder="请填写出运发票号"
              clearable
            >
              <template slot="append">
                <el-link type="primary" @click="dialogTableVisible = true">选择</el-link>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户简称" prop="cust_id">
            <el-select v-model="statForm.cust_id" size="small" placeholder="请选择客户简称" filterable>
              <el-option v-for="item in custOptionList" :key="item.cust_id" :label="item.cust_abbr" :value="item.cust_id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="开船日期:" prop="tran_date">
            <el-date-picker v-model="statForm.tran_date" type="date" placeholder="选择开船日期"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="结算金额" prop="stat_total">
            <el-input v-model="statForm.stat_total" disabled placeholder="请填写结算金额"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="公司抬头" prop="cptt_id">
            <el-select v-model="statForm.cptt_id" size="small" placeholder="请选择公司抬头" filterable>
              <el-option v-for="item in cpttList" :key="item.cptt_id" :value="item.cptt_id" :label="item.cptt_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="待审核状态" prop="stat_state">
            <el-select v-model="statForm.stat_state" size="small" placeholder="请选择待审核状态" filterable>
              <el-option v-for="item in statStateList" :key="item.id" :value="item.id" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="备注" prop="stat_remark">
            <el-input
              v-model="statForm.stat_remark"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
              maxlength="255"
              show-word-limit
              placeholder="请填写备注"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_dash"></div>
      <el-row class="vg_mb_5">
        <el-col>
          <div class="vg_mb_16">
            <!--            <el-button type="primary" plain size="small" @click="openPinv">导入采购发票</el-button>-->
            <!--            <el-button type="primary" plain size="small" @click="openTinv">导入转开发票</el-button>-->
            <el-button type="primary" plain size="small" @click="openInvoice">导入发票</el-button>
            <el-button type="danger" plain size="small" @click="delPinv">删除</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <PinvList ref="PinvList" @calcPrice="calcPrice" :statForm="statForm" @handleSelectionChange="handleSelectionChange"></PinvList>
        </el-col>
      </el-row>
      <el-dialog title="导入出运发票号" width="70%" :visible.sync="dialogTableVisible">
        <TranList @tranConfirm="tranConfirm"></TranList>
      </el-dialog>
      <el-dialog title="导入采购发票" width="70%" :visible.sync="dialogTableVisible1">
        <ImportPinv ref="ImportPinv" @childConfirmPurchaes="childConfirmPurchaes" @childChanelPurchaes="childChanelPurchaes"></ImportPinv>
      </el-dialog>
      <el-dialog title="导入转开发票" width="70%" :visible.sync="dialogTableVisible2">
        <ImportTinv ref="ImportTinv" @childConfirmTinv="childConfirmTinv" @childChanelTinv="childChanelTinv"></ImportTinv>
      </el-dialog>
      <el-dialog title="导入发票" width="70%" :visible.sync="dialogTableVisible3">
        <ImportInvoice ref="ImportInvoice" @childConfirmInvoice="childConfirmInvoice" @childChanelInvoice="childChanelInvoice"></ImportInvoice>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { statAPI } from '@api/modules/stat';
import { cpttAPI } from '@api/modules/comptitle';
import { custAPI } from '@/api/modules/cust';
import addHeader from '@/views/component/addHeader';
import inputUser from '@/views/component/inputUser';
import { BigNumber } from 'bignumber.js';
import TranList from './Component/TranList.vue';
import PinvList from './Component/PinvList.vue';
import ImportPinv from './Component/ImportPinv.vue';
import ImportTinv from './Component/ImportTinv.vue';
import helper from '@assets/js/helper';
import ImportInvoice from '@/views/FinanceManagement/StatManage/TabChild/Component/ImportInvoice.vue';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'StatAddMain',
  computed: {
    helper() {
      return helper;
    }
  },
  components: {
    ImportInvoice,
    addHeader,
    inputUser,
    TranList,
    PinvList,
    ImportPinv,
    ImportTinv
  },
  data() {
    return {
      rules: {
        tran_no: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      statForm: {
        tran_no: '',
        tran_id: null,
        cust_id: null,
        tran_date: new Date(),
        stat_total: null,
        cptt_id: null,
        stat_state: 0,
        stat_remark: null,
        stat_part_list: []
      },
      statStateList: [
        { id: 0, label: '可审核' },
        { id: 1, label: '不可审核' }
      ],
      custOptionList: [],
      cpttList: [],
      dialogTableVisible: false,
      dialogTableVisible1: false,
      dialogTableVisible2: false,
      dialogTableVisible3: false,
      selectList: []
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getCptt();
      this.initCust();
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData();
      let statForm = JSON.parse(JSON.stringify(this.statForm));
      statForm = Object.assign(statForm, staffForm);
      if (statForm.tran_date) {
        statForm.tran_date = parseInt(Number(new Date(statForm.tran_date).getTime()) / 1000);
      }
      post(statAPI.addStat, statForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.$refs.PinvList.searchForm.prod_bhsname = '';
            const permId = this.$route.query.perm_id;
            this.jump('/stat_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: permId,
                  form_id: res.data.data.form_id
                })
              )
            });

            this.resetForm('statForm');
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('statForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 打开导入采购发票弹框
    openPinv() {
      if (!this.statForm.tran_no) {
        return this.$message.warning('请先选择或输入出运发票号');
      }
      this.dialogTableVisible1 = true;
      setTimeout(() => {
        this.$refs.ImportPinv.tranNo = this.statForm.tran_no;
        this.$refs.ImportPinv.initData();
      }, 300);
    },
    // 打开导入转开发票弹框
    openTinv() {
      if (!this.statForm.tran_no) {
        return this.$message.warning('请先选择出运发票号');
      }
      this.dialogTableVisible2 = true;
      setTimeout(() => {
        this.$refs.ImportTinv.tranNo = this.statForm.tran_no;
        this.$refs.ImportTinv.initData();
      }, 300);
    },
    // 删除值
    delPinv() {
      let temp = [];
      for (let i = 0; i < this.selectList.length; i++) {
        for (let j = 0; j < this.statForm.stat_part_list.length; j++) {
          if (this.selectList[i].key === this.statForm.stat_part_list[j].key) {
            temp.push(j);
          }
        }
      }
      temp = temp.sort((a, b) => {
        return b - a;
      });
      for (let i of temp) {
        this.statForm.stat_part_list.splice(i, 1);
      }
      for (let i = 0; i < this.statForm.stat_part_list.length; i++) {
        this.statForm.stat_part_list[i].key = Number(i);
      }
      this.calcPrice();
    },
    // 计算结算金额
    calcPrice() {
      let totalA = new BigNumber(0);
      for (let i = 0; i < this.statForm.stat_part_list.length; i++) {
        totalA = totalA.plus(this.statForm.stat_part_list[i].pinv_part_subtotal ? this.statForm.stat_part_list[i].pinv_part_subtotal : 0);
      }
      this.statForm.stat_total = totalA.toFixed(2);
    },
    // 弹框确认值
    childConfirmPurchaes(row) {
      let temp = JSON.parse(JSON.stringify(row));
      temp = temp.filter(item => this.statForm.stat_part_list.every(item1 => item.pinv_id !== item1.pinv_id));
      for (let i = 0; i < temp.length; i++) {
        temp[i].prod_tnweight = this.helper.reservedDigits(temp[i].prod_tnweight);
        temp[i].pinv_part_num = this.helper.haveFour(temp[i].pinv_part_num);
        temp[i].pinv_part_price = this.helper.haveFour(temp[i].pinv_part_price);
        temp[i].pinv_actu_nos = temp[i].invoice_no;
        temp[i].pinv_cptt_name = temp[i].cptt_bname;
        temp[i].pinv_cptt_id = temp[i].cptt_id;
        temp[i].tinv_id = 0;
      }
      this.statForm.stat_part_list = this.statForm.stat_part_list.concat(temp);
      for (let i = 0; i < this.statForm.stat_part_list.length; i++) {
        this.statForm.stat_part_list[i].key = Number(i);
      }
      this.calcPrice();
      this.dialogTableVisible1 = false;
    },
    // 取消选择值
    childChanelPurchaes() {
      this.dialogTableVisible1 = false;
    },
    // 转开发票弹框确认值
    childConfirmTinv(row) {
      let temp = JSON.parse(JSON.stringify(row));
      temp = temp.filter(item => this.statForm.stat_part_list.every(item1 => item.tinv_id !== item1.tinv_id));
      let temp1 = [];
      for (let i = 0; i < temp.length; i++) {
        let item = {};
        item.pinv_no = temp[i].tinv_no;
        item.supp_abbr = temp[i].supp_abbr;
        item.podr_no = temp[i].podr_no;
        item.pinv_actu_nos = temp[i].invoice_no;
        item.prod_bhsname = temp[i].prod_hsname;
        item.prod_tnweight = this.helper.reservedDigits(temp[i].prod_tnweight);
        item.pinv_part_unit = temp[i].pinv_part_unit;
        item.pinv_part_num = this.helper.haveFour(temp[i].pinv_part_num);
        item.pinv_part_price = temp[i].prod_price;
        item.prod_unit = temp[i].prod_unit;
        item.pinv_part_subtotal = this.helper.haveFour(temp[i].tinv_invtotal);
        item.pinv_cptt_name = temp[i].cptt_bname;
        item.pinv_cptt_id = temp[i].cptt_id;
        item.tinv_id = temp[i].tinv_id;
        item.tinv_part_id = temp[i].tinv_part_id;
        temp1.push(item);
      }
      this.statForm.stat_part_list = this.statForm.stat_part_list.concat(temp1);
      for (let i = 0; i < this.statForm.stat_part_list.length; i++) {
        this.statForm.stat_part_list[i].key = Number(i);
      }
      this.calcPrice();
      this.dialogTableVisible2 = false;
    },
    // 转开发票取消选择值
    childChanelTinv() {
      this.dialogTableVisible2 = false;
    },
    // 子表勾选值
    handleSelectionChange(row) {
      this.selectList = JSON.parse(JSON.stringify(row));
    },
    // 选择出运发票号
    tranConfirm(row) {
      this.statForm.tran_no = helper.transverseTranNo(row.tran_no).trim();
      this.statForm.tran_id = row.tran_id;
      this.statForm.cptt_id = row.cptt_aid;
      this.statForm.cust_id = row.cust_id;
      this.statForm.stat_part_list = [];
      this.statForm.stat_total = '0.0000';
      this.dialogTableVisible = false;
    },
    // 获取客户
    initCust() {
      get(custAPI.getAllCustsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.custOptionList = res.data.data.list;
            return;
          }
          this.$message.error(res.data.code);
        })
        .catch(res => {
          this.$message.error(res.data.code);
        });
    },
    //获取公司抬头
    getCptt() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.statForm.stat_part_list = [];
    },
    //打开导入发票弹窗
    openInvoice() {
      if (!this.statForm.tran_no) {
        return this.$message.warning('请先选择出运发票号');
      }
      this.dialogTableVisible3 = true;
      setTimeout(() => {
        this.$refs.ImportInvoice.tranNo = this.statForm.tran_no;
        let temp = [];
        let temp2 = [];
        this.statForm.stat_part_list.forEach(item => {
          temp.push(item.pinv_part_id);
          temp2.push(item.tinv_id);
        });
        this.$refs.ImportInvoice.pinv_part_ids = temp.join(',');
        this.$refs.ImportInvoice.tinv_ids = temp2.join(',');
        this.$refs.ImportInvoice.initData();
      }, 300);
    },
    //导入发票确认值
    childConfirmInvoice(val) {
      //添加数据
      this.statForm.stat_part_list = this.statForm.stat_part_list.concat(val);
      //计算金额，关闭窗口，赋值key
      for (let i = 0; i < this.statForm.stat_part_list.length; i++) {
        this.statForm.stat_part_list[i].key = Number(i);
      }
      this.calcPrice();
      this.dialogTableVisible3 = false;
    },
    // 导入发票取消选择值
    childChanelInvoice() {
      this.dialogTableVisible3 = false;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #c2c2c2;
  margin: 10px 0;
}
</style>
